'use strict';

Gri.module({
  name: 'section-gamma',
  ieVersion: null,
  $el: $('.section-gamma'),
  container: '.section-gamma',
  fn: function () {

    const $this = this.$el;
    resizeController();
    $(window).on('resize', resizeController);

    function resizeController() {
      let windowW = $(window).width();

      if(windowW < 768) {
        $this.find('.group-icons-alpha ul').addClass('floating');
      } else {
        $this.find('.group-icons-alpha ul').removeClass('floating');
      }
    }

  }
});
